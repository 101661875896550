<template>
  <div class="activate-site">
    <!-- Header Content -->
    <div class="header-site" style="font-size: 30px">
      <router-link to="/">
        <b-button style="display: flex; background-color: white; color: black">
          Back</b-button
        >
      </router-link>
      {{ "Export Email List for Active Sites" }}
    </div>
    <div class="create-center">
      <b-form id="form-style" @submit="onSubmit" @reset="onReset">
        <!-- Radio For Email List Type -->
        <div class="radio-row">
          <b-form-group
            label="Create list for: "
            class="radio-button-label"
          ></b-form-group>
          <!-- Make button + label for each option -->
          <div class="custom-control custom-radio custom-control-inline">
            <input
              type="radio"
              name="radioInline"
              id="all-users"
              class="custom-control-input"
              :value="''"
              v-model="role"
              required
            />
            <label class="custom-control-label" for="all-users"
              >All users (admin & users)</label
            >
            <div class="custom-control custom-radio custom-control-inline">
              <input
                type="radio"
                name="radioInline"
                id="admins-only"
                class="custom-control-input"
                :value="'site-admin'"
                v-model="role"
              />
              <label class="custom-control-label" for="admins-only"
                >Admins only</label
              >
            </div>
          </div>
        </div>
        <b-form-group class="buttons-container">
          <b-button
            type="submit"
            variant="outline-primary"
            style="margin-right: 5px"
            :disabled="loading"
            >Create Email List</b-button
          >
          <b-button
            type="reset"
            variant="outline-danger"
            style="margin-left: 5px"
            :disabled="loading"
            >Reset</b-button
          >
        </b-form-group>
        <!-- Textarea for email list  -->
        <b-form-group
          class="textarea-label"
          label="Email List"
          id="input-group-2"
        >
          <b-form-textarea
            plaintext
            autocomplete="off"
            rows="3"
            id="emailList"
            v-model="emailList"
            type="email"
            placeholder="Email list will appear here..."
            :disabled="loading"
          >
          </b-form-textarea>
        </b-form-group>
      </b-form>
      <h1 v-if="loading">Loading...</h1>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      role: "",
      emailList: "",
      loading: false,
    };
  },
  methods: {
    // Create Email List button
    onSubmit(event) {
      event.preventDefault();
      this.loading = true;
      let emailList = "";
      // will fetch all users if this.role = "" or only site-admins if this.role = "site-admin"
      this.$api
        .get(`/activeUserEmails/${this.role}`)
        .then((res) => {
          const users = res.data;
          for (let i = 0; i < users.length; i++) {
            emailList += users[i] + ", ";
          }
          const removeLastComma = emailList.slice(0, -2); // remove last comma
          this.emailList = removeLastComma;
          this.loading = false;
        })
        .then(() => this.copyTextToClipboard());
    },
    async copyTextToClipboard() {
      try {
        await navigator.clipboard.writeText(this.emailList);
        alert("Email list copied to clipboard");
      } catch (e) {
        alert("Problem with copying to clipboard");
      }
    },
    onReset(event) {
      event.preventDefault();
      this.role = "";
      this.emailList = "";
    },
  },
};
</script>

<style>
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
.radio-row {
  display: flex;
  flex-direction: row;
}

.radio-button-label {
  font-weight: bold;
  margin-right: 10px;
}
.header-site {
  padding: 30px;
  background-color: #133353;
  color: white;
}
.btn-outline-primary {
  color: #133353 !important;
  border-color: #133353 !important;
}
.btn-outline-primary:hover {
  color: white !important;
  background-color: #133353 !important;
}
.btn-outline-danger {
  color: #f08080 !important;
  border-color: #f08080 !important;
}
.btn-outline-danger:hover {
  color: #f08080 !important;
  border-color: #f08080 !important;
}
.create-center {
  margin-top: 20px;
  padding-left: 100px;
  padding-right: 100px;
  align-content: center;
}
.custom-control-label {
  margin-right: 10px;
}
.buttons-container {
  /* margin-top: 30px; */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.textarea-label {
  font-weight: bold;
  text-align: left;
  margin-top: 30px;
}

@media only screen and (min-width: 1000px) {
  .create-center {
    width: 1000px;
    display: inline-block;
  }
}
</style>
