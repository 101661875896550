<template>
  <div class="activate-site">
    <!-- Header Content -->
    <div class="header-site" style="font-size: 30px">
      <router-link to="/">
        <b-button style="display: flex; background-color: white; color: black">
          Back</b-button
        >
      </router-link>
      {{ "Add Client Dataset" }}
    </div>
    <div class="create-center">
      <p>
        Input a name for the new client dataset. The name must be unique and
        have no spaces.
      </p>
      <b-form id="form-style" @submit="onSubmit" @reset="onReset">
        <!-- User Email  -->
        <b-form-group id="input-group-2">
          <b-form-input
            @keydown.space.prevent
            autocomplete="off"
            id="input-2"
            v-model="newClientDataName"
            type="text"
            placeholder="Client Label"
            required
          >
          </b-form-input>
        </b-form-group>

        <b-form-group style="padding: 10px">
          <b-button
            type="submit"
            variant="outline-primary"
            style="margin-right: 5px"
            >Submit</b-button
          >
          <b-button
            type="reset"
            variant="outline-danger"
            style="margin-left: 5px"
            >Reset</b-button
          >
        </b-form-group>
      </b-form>
    </div>
  </div>
</template>

<script>
import createClientCollectionMixin from "@/mixins/createClientCollection.js";
export default {
  mixins: [createClientCollectionMixin],
  data() {
    return {
      newClientDataName: "",
    };
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      this.createClientCollection(this.newClientDataName, true);
    },
    onReset(event) {
      event.preventDefault();
      this.newClientDataName = "";
    },
  },
};
</script>

<style>
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");

.header-site {
  padding: 30px;
  background-color: #133353;
  color: white;
}

.custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #d6e6f0 !important;
}
.btn-outline-primary {
  color: #133353 !important;
  border-color: #133353 !important;
}
.btn-outline-primary:hover {
  color: white !important;
  background-color: #133353 !important;
}
.btn-outline-danger {
  color: #f08080 !important;
  border-color: #f08080 !important;
}

.btn-outline-danger:hover {
  color: #f08080 !important;
  border-color: #f08080 !important;
}
.upload-file {
  background-color: #fde5c9;
  padding: 10px;
}
.create-center {
  margin-top: 20px;
  padding-left: 100px;
  padding-right: 100px;
  align-content: center;
}

@media only screen and (min-width: 1000px) {
  .create-center {
    width: 1000px;
    display: inline-block;
  }
}
</style>
