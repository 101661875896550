<template>
  <div class="site-creator">
    <!-- Header Content -->
    <div class="header-site" style="font-size: 30px">
      <router-link to="/">
        <b-button style="display: flex; background-color: white; color: black">
          Back</b-button
        >
      </router-link>
      {{ "SITE CREATOR" }}
    </div>
    <div class="create-center">
      <Form></Form>
    </div>
  </div>
</template>

<script>
import Form from "../components/Form.vue";

export default {
  components: {
    Form
  }
};
</script>

<style>
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");

.header-site {
  padding: 30px;
  background-color: #133353;
  color: white;
}

.custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #d6e6f0 !important;
}
.btn-outline-primary {
  color: #133353 !important;
  border-color: #133353 !important;
}
.btn-outline-primary:hover {
  color: white !important;
  background-color: #133353 !important;
}
.btn-outline-danger {
  color: #f08080 !important;
  border-color: #f08080 !important;
}

.btn-outline-danger:hover {
  color: #f08080 !important;
  border-color: #f08080 !important;
}
.upload-file {
  background-color: #fde5c9;
  padding: 10px;
}
.create-center {
  margin-top: 20px;
  padding-left: 100px;
  padding-right: 100px;
  align-content: center;
}

@media only screen and (min-width: 1000px) {
  .create-center {
    width: 1000px;
    display: inline-block;
  }
}
</style>
