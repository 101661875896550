<template>
  <div>
    <section id="login" v-bind:class="isShake">
      <form>
        <h1>🚀 Admin Dashboard 🚀</h1>
        <button v-on:click="login">Access Admin Dashboard</button>
      </form>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    isShake: function() {
      if (this.shake == true) {
        return "shake";
      }
      return "none";
    },
  },
  methods: {
    login(event) {
      event.preventDefault();
      this.$auth.loginWithRedirect();
    },
  },
};
</script>

<style scoped>
#login {
  margin: auto;
  border-radius: 20%;
  margin-top: 5vh;
  vertical-align: middle;
  padding: 5%;
  width: 40%;
  font-size: 1.8vw;
  box-shadow: 0px 0px 25px rgb(206, 205, 205);
}
body {
  background-image: url("https://images-assets.nasa.gov/image/6900952/6900952~orig.jpg");
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgb(0, 0, 0);
}

section {
  background-color: rgba(255, 255, 255, 0.9);
  width: 25%;
  min-height: 25%;
  display: flex;
  flex-direction: column;
  color: rgb(0, 0, 0);
}
form {
  display: flex;
  flex-direction: column;
  padding: 15px;
}
h2 {
  color: #252525;
  margin-left: auto;
  margin-right: auto;
}

.info {
  width: 100%;
  padding: 1em 5px;
  text-align: center;
  min-height: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.info.error {
  border: 1px solid red;
  background-color: #ff3c41;
  color: black;
}
.info p {
  margin: auto;
  padding: 5px;
}
.info.good {
  border: 1px solid #416d50;
  background-color: #47cf73;
  color: #416d50;
}

input {
  height: 50px;
  padding: 5px 5px;
  margin: 10px 0px;
  background-color: #000000;
  color: white;
  border: none;
  border-radius: 5px;
}
button {
  height: 40px;
  padding: 5px 5px;
  margin: 10px 0px;
  font-weight: bold;
  background-color: #000000c7;
  border: none;
  color: #ffffff;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
}
button:hover {
  background-color: #000000d7;
  color: white;
  border: 2px solid rgb(255, 255, 255);
}

@-webkit-keyframes shake {
  from,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate(10px, 0, 0);
  }
}

.shake {
  animation-name: shake;
  animation-duration: 1s;
}

@media screen and (max-width: 780px) {
  section {
    width: 90%;
  }
  #login {
    font-size: 5vw;
    width: 85%;
    border-radius: 10%;
  }
}
</style>
