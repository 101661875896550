<template>
  <div>
    <!-- Header Content -->
    <div class="header-site" style="font-size: 30px">
      <router-link to="/">
        <b-button style="display: flex; background-color: white; color: black">
          Back</b-button
        >
      </router-link>
      {{ "Scrape New Data" }}
    </div>
    <div class="create-center">
      <SiteScrapingForm></SiteScrapingForm>
    </div>
  </div>
</template>

<script>
import SiteScrapingForm from "../components/SiteScrapingForm.vue";

export default {
  components: {
    SiteScrapingForm,
  },
};
</script>

<style>
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");

.header-site {
  padding: 30px;
  background-color: #133353;
  color: white;
}
.create-center {
  margin-top: 20px;
  padding-left: 100px;
  padding-right: 100px;
  align-content: center;
}
</style>
