<template>
  <div class="main-container">
    <!-- Main Form Content -->
    <b-form id="form-style" @submit="onSubmit" @reset="onReset" v-if="show">
      <b-form-group id="input-group-1" label="Form To Create A New Demo Site ">
        <b-form-input
          id="input-1"
          autocomplete="off"
          v-model="form.styleName"
          type="text"
          placeholder="Organization Name - Brown University (RI)"
          required
        >
        </b-form-input>
      </b-form-group>

      <!-- Title -->
      <b-form-group id="input-group-2">
        <b-form-input
          autocomplete="off"
          id="input-2"
          v-model="form.title"
          type="text"
          placeholder="Site Title - Brown Hall of Fame"
          required
        >
        </b-form-input>
      </b-form-group>

      <!-- Twitter URL -->
      <b-form-group id="input-group-3">
        <b-form-input
          id="input-3"
          v-model="twitterURL"
          autocomplete="off"
          type="text"
          placeholder="Twitter URL Optional"
        >
        </b-form-input>
      </b-form-group>

      <!-- Color Picker -->
      <b-form-group
        id="input-group-4"
        label="Click to Pick Primary Color:"
        label-for="input-4"
      >
        <b-form-input
          id="input-2.5"
          v-model="form.primaryColor"
          type="color"
          value="ffffff"
          format="hex"
          required
        >
        </b-form-input>
      </b-form-group>

      <!-- Dropzones for file upload -->
      <b-form-group
        class="upload-file"
        v-for="dropzone in dropzoneConfigs"
        :key="dropzone.id"
      >
        <vue-dropzone
          :id="dropzone.id"
          :options="dropzone.options"
          @vdropzone-success="dropzone.success"
          @vdropzone-removed-file="dropzone.remove"
        >
        </vue-dropzone>
      </b-form-group>

      <!-- Data selection radio buttons -->
      <div class="flex-row">
        <div class="radio-btn-container">
          <input
            type="radio"
            id="existing-data"
            value="existing"
            v-model="dataSelection"
            class="radio-btn-label"
          />
          <label for="existing-data">Select Existing Data</label>
        </div>
        <div class="radio-btn-container">
          <input
            type="radio"
            id="new-data"
            value="new"
            v-model="dataSelection"
            class="radio-btn-label"
          />
          <label for="new-data">Create Blank Data</label>
        </div>
      </div>

      <!-- Dropdown style Name -->
      <!-- only show if internal user -->
      <b-form-group
        v-if="dataSelection === 'existing'"
        id="input-group-5"
        label="Select Data to Attach to this Style:"
        label-for="input-5"
      >
        <MultiSelect
          v-if="(clientCollectionIDs || []).length != 0"
          id="input-6"
          required
          :options="clientCollectionIDs"
          label="name"
          v-model="siteData"
          :searchable="true"
        />

        <b-form-input
          @keydown.space.prevent
          v-else
          id="input-3"
          v-model="newClientName"
          autocomplete="off"
          type="text"
          placeholder="Name For New Dataset"
          required
        >
        </b-form-input>
      </b-form-group>
      <!-- New collection name input -->
      <b-form-group
        v-if="dataSelection === 'new'"
        id="input-group-7"
        label="Name For New Dataset:"
        label-for="input-7"
      >
        <b-form-input
          @keydown.space.prevent
          id="input-7"
          v-model="newClientName"
          autocomplete="off"
          type="text"
          placeholder="Name For New Dataset"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group style="padding: 10px">
        <b-button
          type="submit"
          variant="outline-primary"
          style="margin-right: 5px"
          >Submit</b-button
        >
        <b-button type="reset" variant="outline-danger" style="margin-left: 5px"
          >Reset</b-button
        >
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import createClientCollectionMixin from "@/mixins/createClientCollection.js";
import createSiteIDMixin from "@/mixins/createSiteID.js";
import createSiteDropzoneMixin from "@/mixins/createSiteDropzoneMixin.js";

import MultiSelect from "vue-multiselect";

export default {
  name: "Form",
  mixins: [
    createClientCollectionMixin,
    createSiteIDMixin,
    createSiteDropzoneMixin,
  ],
  components: {
    vueDropzone: vue2Dropzone,
    MultiSelect,
  },
  data() {
    return {
      twitterURL: "",
      newClientName: "",
      selectedSiteData: null,
      dataSelection: "existing",
      form: {
        styleName: "",
        primaryColor: "#000000",
        title: "",
        clientID: "",
        primaryLogo: [],
        backgroundImage: [],
        media: [],
      },
      show: true,
    };
  },
  methods: {
    /* On submit changes the approporiate fields of the form to be submitted
        and posts the form to DB */
    onSubmit(event) {
      event.preventDefault();
      // if user doesn't select a site or create a new one, alert them
      if (
        (this.dataSelection === "existing" && this.selectedSiteData == null) ||
        (this.dataSelection === "new" && this.newClientName.trim() === "")
      ) {
        alert(
          "Site data cannot be empty. Please select data to attach to this style or create a new blank dataset."
        );
        return;
      }
      this.generateFormToSend();

      // create a new client collection if the user doesn't already have one
      if (
        (Object.keys(this.clientCollectionIDs) || []).length == 0 ||
        this.dataSelection === "new"
      ) {
        this.createClientCollection(this.newClientName).then((id) => {
          // create a new site
          this.form.clientID = id;
          this.createSiteID();
        });
      } else {
        // create a new site
        this.createSiteID();
      }
    },
    /* Resets Form */
    onReset(event) {
      event.preventDefault();
      const output = window.confirm(
        "Are you sure you want to reset your progress?"
      );
      if (output == true) {
        // Reset our form values
        this.twitterURL = "";
        this.newClientName = "";
        this.form = {
          styleName: "",
          primaryColor: "#000000",
          title: "",
          clientID: "",
        };
        // Trick to reset/clear native browser form validation state
        this.show = false;
        this.$nextTick(() => {
          this.show = true;
        });
      }
    },
    /* Function to convert values of HEX to RGBA */
    hexToRgbA(hex) {
      var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);
      return "rgba(" + r + ", " + g + ", " + b + ", " + 1 + ")";
    },
    /* Allows us to customize the transparency */
    TransparencyMutator(rgba, newA) {
      let spliced = rgba.split(",");
      let first = spliced[0] + "," + spliced[1] + "," + spliced[2];
      first = first + ", " + newA + ")";
      return first;
    },
    /* Functionality to copy newly created site url to clipboard */
    async copyToClipboard(url) {
      const input = document.createElement("input");
      document.body.appendChild(input);
      input.value = url;

      // 1) Select the text
      input.focus();
      input.select();

      // 2) Copy text to clipboard
      const isSuccessful = document.execCommand("copy");
      document.body.removeChild(input);

      // 3) Catch errors
      if (!isSuccessful) {
        console.error("Failed to copy text.");
      } else {
        alert("Successfully created site and copied URL to clipboard!");
      }
    },
  },
  computed: {
    clientCollectionIDs() {
      return this.$store.getters.getClientCollectionIDs;
    },
    siteData: {
      get: function() {
        return this.selectedSiteData;
      },
      set: function(site) {
        this.selectedSiteData = site;
        this.form.clientID = site.id;
      },
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
#login {
  margin: auto;
  border-radius: 20%;
  margin-top: 5vh;
  vertical-align: middle;
  padding: 5%;
  width: 40%;
  font-size: 1.8vw;
  box-shadow: 0px 0px 25px rgb(206, 205, 205);
}
body {
  background-image: url("https://images-assets.nasa.gov/image/6900952/6900952~orig.jpg");
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgb(0, 0, 0);
}

section {
  background-color: rgba(255, 255, 255, 0.9);
  width: 25%;
  min-height: 25%;
  display: flex;
  flex-direction: column;
  color: rgb(0, 0, 0);
}
form {
  display: flex;
  flex-direction: column;
  padding: 15px;
}
h2 {
  color: #252525;
  margin-left: auto;
  margin-right: auto;
}

.info {
  width: 100%;
  padding: 1em 5px;
  text-align: center;
  min-height: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.info.error {
  border: 1px solid red;
  background-color: #ff3c41;
  color: black;
}
.info p {
  margin: auto;
  padding: 5px;
}
.info.good {
  border: 1px solid #416d50;
  background-color: #47cf73;
  color: #416d50;
}

input {
  height: 50px;
  padding: 5px 5px;
  margin: 5px 0px;
  border-radius: 5px;
}
button {
  height: 40px;
  padding: 5px 5px;
  margin: 10px 0px;
  font-weight: bold;
  color: #133353;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
}
button:hover {
  background-color: #f08080;
  color: white;
  border: 2px solid rgb(255, 255, 255);
}
.btn-outline-danger:hover {
  color: white !important;
}
#customdropzone .dz-preview .dz-details {
  color: white;
  transition: opacity 0.2s linear;
  text-align: center;
}
#customdropzone .dz-success-mark .dz-error-mark {
  display: none !important;
}

.shake {
  animation-name: shake;
  animation-duration: 1s;
}

.upload-file {
  justify-content: center;
}

#customdropzone {
  padding: 10px;
}

#customdropzone .dz-default .dz-message {
  margin-top: 10px !important;
}
.flex-row {
  display: flex;
}
.radio-btn-container {
  display: flex;
  align-items: center;
  margin-right: 20px;
  font-weight: bold;
}
label {
  margin-bottom: 0;
  margin-left: 10px;
}

@media screen and (max-width: 780px) {
  section {
    width: 90%;
  }
  #login {
    font-size: 5vw;
    width: 85%;
    border-radius: 10%;
  }
}
</style>
