export default {
  methods: {
    /**
     * Handles API requests that will create a client collection given the name of the new client collection.
     *
     * @param {String} name the name of the new client collection
     * @param {Boolean | undefined} redirectHome optional param that will redirect user to homepage
     * @returns a promise so that we can continue promise chaining if necessary.
     */
    createClientCollection(name, redirectHome) {
      return Promise.resolve(
        this.$api
          .post(`clientCollection/${name}`)
          .then((res) => {
            if (redirectHome) {
              alert("New Blank Client Successfully Created");
              this.$router.push({ path: "/" });
            }
            // Return the id from the response for Create New Site Form
            return res.data.id; 
          })
          .catch(() => {
            alert(
              "Error occurred. This name may already exist. If that is not the case, please contact support."
            );
          })
      );
    },
  },
};
