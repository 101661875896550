<template>
  <div class="activate-site">
    <!-- Header Content -->
    <div class="header-site" style="font-size: 30px">
      <router-link to="/">
        <b-button style="display: flex; background-color: white; color: black">
          Back</b-button
        >
      </router-link>
      {{ "Add Site User" }}
    </div>
    <div class="create-center">
      <b-form id="form-style" @submit="onSubmit" @reset="onReset">
        <!-- User Type Radio Buttons: New User Email or Existing User Email -->
        <div class="radio-row">
          <b-form-group
            label="User Type"
            class="input-label"
            id="input-group-user-type"
          >
          </b-form-group>
          <div class="custom-control custom-radio custom-control-inline">
            <input
              class="custom-control-input"
              type="radio"
              name="userType"
              id="newUserEmail"
              value="newUserEmail"
              v-model="userType"
            />
            <label class="custom-control-label" for="newUserEmail"
              >New User Email</label
            >
          </div>
          <div class="custom-control custom-radio custom-control-inline">
            <input
              class="custom-control-input"
              type="radio"
              name="userType"
              id="existingUserEmail"
              value="existingUserEmail"
              v-model="userType"
              @click="clearRole"
            />
            <label class="custom-control-label" for="existingUserEmail"
              >Existing User Email</label
            >
          </div>
        </div>
        <!-- User Email  -->
        <b-form-group
          class="input-label"
          label="Input User's Email:"
          id="input-group-2"
        >
          <b-form-input
            autocomplete="off"
            id="input-2"
            v-model="newUserParams.email"
            type="email"
            placeholder="User Email"
            required
            :disabled="loading"
          >
          </b-form-input>
          <!-- Radio For Role -->
        </b-form-group>
        <div class="radio-row" v-if="userType === 'newUserEmail'">
          <b-form-group label="User Role" class="input-label"></b-form-group>
          <!-- Make button + label for each option -->
          <div class="custom-control custom-radio custom-control-inline">
            <input
              type="radio"
              name="radioInline"
              id="internal"
              class="custom-control-input"
              value="internal"
              v-model="newUserParams.role"
              required
            />
            <label class="custom-control-label" for="internal">Internal</label>
          </div>
          <div class="custom-control custom-radio custom-control-inline">
            <input
              type="radio"
              name="radioInline"
              id="site-admin"
              class="custom-control-input"
              value="site-admin"
              v-model="newUserParams.role"
            />
            <label class="custom-control-label" for="site-admin"
              >Site-Admin</label
            >
          </div>
          <div class="custom-control custom-radio custom-control-inline">
            <input
              type="radio"
              name="radioInline"
              id="site-user"
              class="custom-control-input"
              value="site-user"
              v-model="newUserParams.role"
            />
            <label class="custom-control-label" for="site-user"
              >Site-User</label
            >
          </div>
          <div class="custom-control custom-radio custom-control-inline">
            <input
              type="radio"
              name="radioInline"
              id="create-sites"
              class="custom-control-input"
              value="create-sites"
              v-model="newUserParams.role"
            />
            <label class="custom-control-label" for="create-sites"
              >Create-Sites</label
            >
          </div>
          <div class="custom-control custom-radio custom-control-inline">
            <input
              type="radio"
              name="radioInline"
              id="sales"
              class="custom-control-input"
              value="sales"
              v-model="newUserParams.role"
            />
            <label class="custom-control-label" for="sales">Sales</label>
          </div>
        </div>

        <!-- User SiteID  -->
        <b-form-group
          id="input-group-5"
          class="input-label"
          label="Select SiteID To Give User Permissions For"
          label-for="input-5"
          v-if="
            newUserParams.role === 'site-admin' ||
              newUserParams.role === 'site-user' ||
              newUserParams.role === 'create-sites' ||
              newUserParams.role === ''
          "
        >
          <MultiSelect
            v-if="(getSiteList || []).length != 0"
            id="input-6"
            required
            :options="getSiteList"
            label="styleName"
            v-model="siteData"
            :searchable="true"
          />
        </b-form-group>

        <!-- Access Duration  -->
        <b-form-group
          id="input-group-7"
          class="input-label"
          label="Select Access Duration For User (Optional)"
          label-for="input-7"
          v-if="
            newUserParams.role === 'site-admin' ||
              newUserParams.role === 'site-user' ||
              newUserParams.role === 'create-sites' ||
              newUserParams.role === ''
          "
        >
          <MultiSelect
            id="input-7"
            :options="getAccessDurationOptions"
            label="dropdownLabel"
            track-by="value"
            v-model="accessDuration"
          />
        </b-form-group>
        <b-form-group style="padding: 20px">
          <b-button
            type="submit"
            variant="outline-primary"
            style="margin-right: 5px"
            :disabled="loading"
            >Submit</b-button
          >
          <b-button
            type="reset"
            variant="outline-danger"
            style="margin-left: 5px"
            :disabled="loading"
            >Reset</b-button
          >
        </b-form-group>
      </b-form>
      <h1 v-if="loading">Loading...</h1>
    </div>
  </div>
</template>

<script>
import MultiSelect from "vue-multiselect";

export default {
  components: {
    MultiSelect,
  },
  data() {
    return {
      userType: "newUserEmail",
      selectedSiteID: null,
      accessDuration: null,
      newUserParams: {
        email: "",
        role: "internal",
        siteID: "",
      },
      loading: false,
      availableRoles: [
        "internal",
        "site-admin",
        "site-user",
        "create-sites",
        "sales",
      ],
    };
  },
  computed: {
    siteID() {
      return this.$route.params.siteID;
    },
    getSiteList() {
      return this.$store.getters.getSites;
    },
    siteData: {
      get: function() {
        return this.selectedSiteID;
      },
      set: function(siteObject) {
        this.selectedSiteID = siteObject;
        this.newUserParams.siteID = siteObject.siteID;
      },
    },
    getAccessDurationOptions() {
      return [
        { dropdownLabel: "6 hours", value: 6 },
        { dropdownLabel: "12 hours", value: 12 },
        { dropdownLabel: "24 hours", value: 24 },
        { dropdownLabel: "48 hours", value: 48 },
      ];
    },
  },
  methods: {
    async createNewUserAndGrantAccess() {
      try {
        await this.createUser();
        // Need to grant access to site if user is not internal
        if (!["internal", "sales"].includes(this.newUserParams.role)) {
          await this.grantAccess();
        }
        this.handleSuccess("New User Created");
      } catch (e) {
        this.handleError(e);
      }
    },
    async grantAccessToExistingUser() {
      // Internal users do not need to be granted site access
      if (this.newUserParams.role === "internal") {
        alert("Internal users do not need site access");
        this.loading = false;
        return;
      }
      try {
        await this.grantAccess();
        this.handleSuccess("Access Granted to User");
      } catch (e) {
        this.handleError(e);
      }
    },
    // create new user
    async createUser() {
      return this.$api.post(`/users`, {
        email: this.newUserParams.email,
        role: this.newUserParams.role,
      });
    },
    // grant access to site
    async grantAccess() {
      const grantAccessPayload = {
        email: this.newUserParams.email,
        siteID: this.newUserParams.siteID,
        ...(this.accessDuration
          ? { accessDuration: this.accessDuration.value }
          : {}),
      };
      return this.$api.post("/grantSiteAccess", grantAccessPayload);
    },
    // display success message and redirect to home page
    handleSuccess(message) {
      this.loading = false;
      alert(message);
      this.$router.push("/");
    },
    handleError(e) {
      alert("Error occurred. Please contact support. " + e.response.data.error);
    },
    onSubmit(event) {
      event.preventDefault();
      this.loading = true;
      // if new user, create user and grant access
      if (this.userType === "newUserEmail") {
        this.createNewUserAndGrantAccess();
        // if existing user, just grant access
      } else {
        this.grantAccessToExistingUser();
      }
    },
    onReset(event) {
      event.preventDefault();
      this.newUserParams.email = "";
      this.newUserParams.siteID = "";
      this.newUserParams.role = "";
    },
    clearRole() {
      this.newUserParams.role = "";
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
.radio-buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px 0px;
}
.radio-row {
  display: flex;
  flex-direction: row;
}

.input-label {
  font-weight: bold;
  margin-right: 10px;
  text-align: left;
}
.header-site {
  padding: 30px;
  background-color: #133353;
  color: white;
}

.custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #d6e6f0 !important;
}
.btn-outline-primary {
  color: #133353 !important;
  border-color: #133353 !important;
}
.btn-outline-primary:hover {
  color: white !important;
  background-color: #133353 !important;
}
.btn-outline-danger {
  color: #f08080 !important;
  border-color: #f08080 !important;
}

.btn-outline-danger:hover {
  color: #f08080 !important;
  border-color: #f08080 !important;
}
.upload-file {
  background-color: #fde5c9;
  padding: 10px;
}
.create-center {
  margin-top: 20px;
  padding-left: 100px;
  padding-right: 100px;
  align-content: center;
}

@media only screen and (min-width: 1000px) {
  .create-center {
    width: 1000px;
    display: inline-block;
  }
}
</style>
