export default {
  data() {
    return {
      USE_UPLOADCARE: true,
      mediaURL: "",
      dropzoneConfigs: [
        {
          id: "dropzone1",
          options: {
            url: "",
            thumbnailWidth: 200,
            addRemoveLinks: true,
            maxFiles: 1,
            dictDefaultMessage:
              "<i class='fa fa-cloud-upload'></i> UPLOAD: Background Image ",
            acceptedFiles: ".png, .gif, .jpeg, .jpg, .svg-xml, .ico, .pdf",
            headers: {
              "Cache-Control": null,
              "X-Requested-With": null,
              Authorization: this.$api.getAuthorizationHeader(),
            },
          },
          success: (file, response) =>
            this.uploadMedia("backgroundImage", file, response),
          remove: (file) => this.removeFile(file),
        },
        {
          id: "dropzone2",
          options: {
            url: "",
            thumbnailWidth: 200,
            addRemoveLinks: true,
            dictDefaultMessage:
              "<i class='fa fa-cloud-upload'></i> UPLOAD: Home Screen Backgrounds ",
            acceptedFiles:
              "image/webp, image/png, image/jpeg, image/gif, image/tiff,video/mp4, video/webm, video/quicktime, video/mov",
            headers: {
              "Cache-Control": null,
              "X-Requested-With": null,
              Authorization: this.$api.getAuthorizationHeader(),
            },
          },
          success: (file, response) =>
            this.uploadMedia("media", file, response),
          remove: (file) => this.removeFile(file),
        },
        {
          id: "dropzone3",
          options: {
            url: "",
            thumbnailWidth: 200,
            addRemoveLinks: true,
            maxFiles: 1,
            dictDefaultMessage:
              "<i class='fa fa-cloud-upload'></i> UPLOAD: Primary Logo ",
            acceptedFiles: ".png, .gif, .jpeg, .jpg, .svg-xml, .ico, .pdf",
            headers: {
              "Cache-Control": null,
              "X-Requested-With": null,
              Authorization: this.$api.getAuthorizationHeader(),
            },
          },
          success: (file, response) =>
            this.uploadMedia("primaryLogo", file, response),
          remove: (file) => this.removeFile(file),
        },
      ],
    };
  },
  created() {
    this.mediaURL = this.getMediaEndpointURL();
    this.dropzoneConfigs.forEach((config) => {
      config.options.url = this.getMediaEndpointURL();
    });
  },
  methods: {
    uploadMedia(siteVar, file, response) {
      file.link = response.url;
      file._id = response._id;
      this.form[siteVar].push(response._id);
      file.varName = siteVar;
    },
    removeFile(file) {
      let index = this.form[file.varName].findIndex((id) => id === file._id);
      if (index > -1) {
        this.form[file.varName].splice(index, 1);
      }
    },
    getMediaEndpointURL() {
      return this.USE_UPLOADCARE
        ? this.$api._URL + "/media/v2/siteID/dashboard"
        : this.$api._URL + "/media/siteID/dashboard";
    },
  },
};
